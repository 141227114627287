import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './PageNotFound.module.css'
import PageNotFoundImage from '../../../public/images/icons/pagenotfound.svg'

const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.pageNotFound}>
      <img
        src={PageNotFoundImage}
        alt="page not found"
        className={styles.pageNotFoundImage}
      />
      <h4 className={styles.pageNotFoundTitle}>{t('misc.unknown_page')}</h4>
      <p className={styles.pageNotFoundText}>{t('misc.page_not_found')}</p>
      <button type="button" className={styles.pageNotFoundButton}>
        <a href="/" className={styles.pageNotFoundButtonColor}>
          Go home
        </a>
      </button>
    </div>
  )
}
export default PageNotFound
